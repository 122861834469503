var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('COMPANY_INFORMATION')))]), _c('div', {
    staticClass: "row push"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_vm._l(_vm.formErrors, function (message, key) {
    return _c('div', {
      key: key,
      staticClass: "alert alert-danger"
    }, [_vm._v(" " + _vm._s(message) + " ")]);
  }), _c('FormGroup', {
    attrs: {
      "id": "name",
      "error": _vm.error['name'],
      "label": _vm.$t('NAME'),
      "type": "text",
      "disabled": _vm.disabled || !['company_owner', 'admin'].some(function (r) {
        return _vm.$auth.roles.includes(r);
      })
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _vm.originalValue._id ? _c('FormGroup', {
    attrs: {
      "id": "invoiceEmail",
      "label": _vm.$t('COMPANY_INVOICE_CAPTUR_EMAIL'),
      "type": "text",
      "disabled": true,
      "value": "captur+".concat(_vm.originalValue._id, "@app.etainabl.com"),
      "description": "Sending emails to this address with invoices attached will automatically get loaded into the Invoice Uploader"
    }
  }) : _vm._e(), ['company_owner', 'admin'].some(function (r) {
    return _vm.$auth.roles.includes(r);
  }) ? _c('div', _vm._b({
    staticClass: "form-group row align-items-center"
  }, 'div', _vm.$attrs, false), [_c('div', {
    staticClass: "form-group col-md-8"
  }, [_c('label', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t('LOGO')))]), !_vm.loadingAction.uploadLogo ? _c('input', {
    staticClass: "d-block",
    attrs: {
      "id": "logo",
      "type": "file",
      "name": "logo",
      "accept": ".png"
    },
    on: {
      "change": _vm.onFileSelect
    }
  }) : _vm._e(), _vm.loadingAction.uploadLogo ? _c('Spinner') : _vm._e(), _c('small', {
    staticClass: "font-w600"
  }, [_vm._v(_vm._s(_vm.$t('LOGO_FOMRAT_RULE')))]), _vm.logoUpload.uploaded && _vm.logoUpload.previewUrl ? _c('div', {
    staticClass: "my-3",
    attrs: {
      "id": "logoS3Key"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": _vm.logoUpload.previewUrl
    }
  })]) : _vm._e(), _vm.error['logoS3Key'] ? _c('div', {
    staticClass: "invalid-feedback d-block"
  }, [_vm._v(_vm._s(_vm.$t('LOGO_SUBMISSION_ERROR')))]) : _vm._e()], 1), _c('div', {
    staticClass: "col-md-4 text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onSubmitFile($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('SUBMIT')))])])]) : _vm._e()], 2)]), ['company_owner', 'admin'].some(function (r) {
    return _vm.$auth.roles.includes(r);
  }) ? _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('REPORTING_SETTINGS')) + " ")]) : _vm._e(), ['company_owner', 'admin'].some(function (r) {
    return _vm.$auth.roles.includes(r);
  }) ? _c('div', {
    staticClass: "row push"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "calendarPeriod",
      "label": _vm.$t('CALENDAR_PERIOD'),
      "type": "select",
      "options": _vm.calendarPeriodOptions
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.settings.calendarPeriod,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings, "calendarPeriod", $$v);
      },
      expression: "form.settings.calendarPeriod"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "hideCostData",
      "label": _vm.$t('HIDE_COST_DATA'),
      "type": "radio",
      "options": _vm.yesNoOptions
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.settings.hideCostData,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings, "hideCostData", $$v);
      },
      expression: "form.settings.hideCostData"
    }
  })], 1)]) : _vm._e(), ['company_owner', 'admin'].some(function (r) {
    return _vm.$auth.roles.includes(r);
  }) ? _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('APPLICATION_SETTINGS')) + " ")]) : _vm._e(), ['company_owner', 'admin'].some(function (r) {
    return _vm.$auth.roles.includes(r);
  }) ? _c('div', {
    staticClass: "row push"
  }, [_vm._m(2), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('div', {
    staticClass: "accordin"
  }, [_c('div', {
    staticClass: "card bg-body p-3",
    attrs: {
      "id": "settings-accordion_h1",
      "role": "tab"
    }
  }, [_c('a', {
    staticClass: "font-w600 collapsed card-header",
    attrs: {
      "data-toggle": "collapse",
      "href": "#accordion-invoice-categories"
    }
  }, [_vm._v(_vm._s(_vm.$tc('INVOICE_CATEGORY', 2)) + " "), _vm._m(3)])]), _c('div', {
    staticClass: "collapse show",
    attrs: {
      "id": "accordion-invoice-categories",
      "role": "tabpanel"
    }
  }, [_c('FormGroup', {
    attrs: {
      "id": "settingsInvoiceCategories",
      "description": _vm.$t('INVOICE_CATEGORY_DESCRIPTION'),
      "placeholder": _vm.$t('ADD_CATEGORY_PLACEHOLDER'),
      "type": "select-array",
      "unique-only": true
    },
    model: {
      value: _vm.form.settings.invoiceCategories,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings, "invoiceCategories", $$v);
      },
      expression: "form.settings.invoiceCategories"
    }
  })], 1)])])]) : _vm._e(), ['company_owner', 'admin'].some(function (r) {
    return _vm.$auth.roles.includes(r);
  }) ? _c('div', {
    staticClass: "row push"
  }, [_vm._m(4), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('div', {
    staticClass: "accordin"
  }, [_c('div', {
    staticClass: "card p-3 bg-body",
    attrs: {
      "id": "settings-accordion_h1",
      "role": "tab"
    }
  }, [_c('a', {
    staticClass: "font-w600 collapsed card-header",
    attrs: {
      "data-toggle": "collapse",
      "href": "#accordion-misc-invoice-categories",
      "data-parent": "#accordion"
    }
  }, [_vm._v(_vm._s(_vm.$t('MISC_INVOICE_CAT')) + " "), _vm._m(5)])]), _c('div', {
    staticClass: "collapse",
    attrs: {
      "id": "accordion-misc-invoice-categories",
      "role": "tabpanel"
    }
  }, [_c('FormGroup', {
    attrs: {
      "id": "settingsMiscInvoiceCategoriess",
      "description": _vm.$t('MISC_INVOICE_CAT_DESCRIPTION'),
      "placeholder": _vm.$t('ADD_CATEGORY_PLACEHOLDER'),
      "type": "select-array",
      "unique-only": true
    },
    model: {
      value: _vm.form.settings.miscInvoiceCategories,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings, "miscInvoiceCategories", $$v);
      },
      expression: "form.settings.miscInvoiceCategories"
    }
  })], 1)])])]) : _vm._e(), ['company_owner', 'admin'].some(function (r) {
    return _vm.$auth.roles.includes(r);
  }) ? _c('div', {
    staticClass: "row push"
  }, [_vm._m(6), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('div', {
    staticClass: "accordin"
  }, [_c('div', {
    staticClass: "card p-3 bg-body",
    attrs: {
      "id": "settings-accordion_h1",
      "role": "tab"
    }
  }, [_c('a', {
    staticClass: "font-w600 collapsed card-header",
    attrs: {
      "data-toggle": "collapse",
      "href": "#accordion-message-categories"
    }
  }, [_vm._v(_vm._s(_vm.$tc('MESSAGE_CATEGORY', 2)) + " "), _vm._m(7)])]), _c('div', {
    staticClass: "collapse",
    attrs: {
      "id": "accordion-message-categories",
      "role": "tabpanel"
    }
  }, [_c('FormGroup', {
    attrs: {
      "id": "settingsMessageCategories",
      "description": _vm.$t('MESSAGE_CATEGORY_DESCRIPTION'),
      "placeholder": _vm.$t('ADD_CATEGORY_PLACEHOLDER'),
      "type": "select-array",
      "unique-only": true
    },
    model: {
      value: _vm.form.settings.messageCategories,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings, "messageCategories", $$v);
      },
      expression: "form.settings.messageCategories"
    }
  })], 1)])])]) : _vm._e(), ['company_owner', 'admin'].some(function (r) {
    return _vm.$auth.roles.includes(r);
  }) ? _c('div', {
    staticClass: "row push"
  }, [_vm._m(8), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('div', {
    staticClass: "accordin"
  }, [_c('div', {
    staticClass: "card p-3 bg-body",
    attrs: {
      "id": "settings-accordion_h1",
      "role": "tab"
    }
  }, [_c('a', {
    staticClass: "font-w600 collapsed card-header",
    attrs: {
      "data-toggle": "collapse",
      "href": "#accordion-document-categories"
    }
  }, [_vm._v(_vm._s(_vm.$tc('DOCUMENT_CATEGORY', 2)) + " "), _vm._m(9)])]), _c('div', {
    staticClass: "collapse",
    attrs: {
      "id": "accordion-document-categories",
      "role": "tabpanel"
    }
  }, [_c('FormGroup', {
    attrs: {
      "id": "settingsDocumentCategories",
      "description": _vm.$t('DOCUMENT_CATEGORY_DESCRIPTION'),
      "placeholder": _vm.$t('ADD_CATEGORY_PLACEHOLDER'),
      "type": "select-array",
      "unique-only": true
    },
    model: {
      value: _vm.form.settings.documentCategories,
      callback: function callback($$v) {
        _vm.$set(_vm.form.settings, "documentCategories", $$v);
      },
      expression: "form.settings.documentCategories"
    }
  })], 1)])])]) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "float-right"
  }, [_c('i', {
    staticClass: "fas"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "float-right"
  }, [_c('i', {
    staticClass: "fas"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "float-right"
  }, [_c('i', {
    staticClass: "fas"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "float-right"
  }, [_c('i', {
    staticClass: "fas"
  })]);
}]

export { render, staticRenderFns }