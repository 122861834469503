<template>
  <div>
    <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info text-muted mr-1"></i> {{ $t('COMPANY_INFORMATION') }}</h2>
    <div class="row push">
      <div class="col-lg-4">
        <p class="text-muted"></p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <div v-for="(message, key) in formErrors" :key="key" class="alert alert-danger">
          {{ message }}
        </div>
        <FormGroup
          id="name"
          v-model="form.name"
          :error="error['name']"
          :label="$t('NAME')"
          type="text"
          :disabled="disabled || !['company_owner', 'admin'].some(r => $auth.roles.includes(r))"
          @input="$emit('input', form)"
        />
        <FormGroup
          v-if="originalValue._id"
          id="invoiceEmail"
          :label="$t('COMPANY_INVOICE_CAPTUR_EMAIL')"
          type="text"
          :disabled="true"
          :value="`captur+${originalValue._id}@app.etainabl.com`"
          description="Sending emails to this address with invoices attached will automatically get loaded into the Invoice Uploader"
        />

        <div v-if="['company_owner', 'admin'].some(r => $auth.roles.includes(r))" class="form-group row align-items-center" v-bind="$attrs">
          <div class="form-group col-md-8">
            <label class="d-block">{{ $t('LOGO') }}</label>
            <input v-if="!loadingAction.uploadLogo" id="logo" class="d-block" type="file" name="logo" accept=".png" @change="onFileSelect" />
            <Spinner v-if="loadingAction.uploadLogo" />
            <small class="font-w600">{{ $t('LOGO_FOMRAT_RULE') }}</small>
            <div v-if="logoUpload.uploaded && logoUpload.previewUrl" id="logoS3Key" class="my-3">
              <img :src="logoUpload.previewUrl" class="img-fluid" />
            </div>
            <div v-if="error['logoS3Key']" class="invalid-feedback d-block">{{ $t('LOGO_SUBMISSION_ERROR') }}</div>
          </div>

          <div class="col-md-4 text-right">
            <button class="btn btn-primary" @click.prevent="onSubmitFile">{{ $t('SUBMIT') }}</button>
          </div>
        </div>
      </div>
    </div>

    <h2 v-if="['company_owner', 'admin'].some(r => $auth.roles.includes(r))" class="content-heading pt-0">
      <i class="fa fa-fw fa-info text-muted mr-1"></i> {{ $t('REPORTING_SETTINGS') }}
    </h2>
    <div v-if="['company_owner', 'admin'].some(r => $auth.roles.includes(r))" class="row push">
      <div class="col-lg-4">
        <p class="text-muted"></p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <FormGroup
          id="calendarPeriod"
          v-model="form.settings.calendarPeriod"
          :label="$t('CALENDAR_PERIOD')"
          type="select"
          :options="calendarPeriodOptions"
          @input="$emit('input', form)"
        />
        <FormGroup
          id="hideCostData"
          v-model="form.settings.hideCostData"
          :label="$t('HIDE_COST_DATA')"
          type="radio"
          :options="yesNoOptions"
          @input="$emit('input', form)"
        />
      </div>
    </div>

    <h2 v-if="['company_owner', 'admin'].some(r => $auth.roles.includes(r))" class="content-heading pt-0">
      <i class="fa fa-fw fa-info text-muted mr-1"></i> {{ $t('APPLICATION_SETTINGS') }}
    </h2>
    <div v-if="['company_owner', 'admin'].some(r => $auth.roles.includes(r))" class="row push">
      <div class="col-lg-4">
        <p class="text-muted"></p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <div class="accordin">
          <div id="settings-accordion_h1" class="card bg-body p-3" role="tab">
            <a class="font-w600 collapsed card-header" data-toggle="collapse" href="#accordion-invoice-categories"
              >{{ $tc('INVOICE_CATEGORY', 2) }}
              <span class="float-right">
                <i class="fas"></i>
              </span>
            </a>
          </div>
          <div id="accordion-invoice-categories" class="collapse show" role="tabpanel">
            <FormGroup
              id="settingsInvoiceCategories"
              v-model="form.settings.invoiceCategories"
              :description="$t('INVOICE_CATEGORY_DESCRIPTION')"
              :placeholder="$t('ADD_CATEGORY_PLACEHOLDER')"
              type="select-array"
              :unique-only="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="['company_owner', 'admin'].some(r => $auth.roles.includes(r))" class="row push">
      <div class="col-lg-4">
        <p class="text-muted"></p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <div class="accordin">
          <div id="settings-accordion_h1" class="card p-3 bg-body" role="tab">
            <a class="font-w600 collapsed card-header" data-toggle="collapse" href="#accordion-misc-invoice-categories" data-parent="#accordion"
              >{{ $t('MISC_INVOICE_CAT') }}
              <span class="float-right">
                <i class="fas"></i>
              </span>
            </a>
          </div>
          <div id="accordion-misc-invoice-categories" class="collapse" role="tabpanel">
            <FormGroup
              id="settingsMiscInvoiceCategoriess"
              v-model="form.settings.miscInvoiceCategories"
              :description="$t('MISC_INVOICE_CAT_DESCRIPTION')"
              :placeholder="$t('ADD_CATEGORY_PLACEHOLDER')"
              type="select-array"
              :unique-only="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="['company_owner', 'admin'].some(r => $auth.roles.includes(r))" class="row push">
      <div class="col-lg-4">
        <p class="text-muted"></p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <div class="accordin">
          <div id="settings-accordion_h1" class="card p-3 bg-body" role="tab">
            <a class="font-w600 collapsed card-header" data-toggle="collapse" href="#accordion-message-categories"
              >{{ $tc('MESSAGE_CATEGORY', 2) }} <span class="float-right"> <i class="fas"></i> </span
            ></a>
          </div>
          <div id="accordion-message-categories" class="collapse" role="tabpanel">
            <FormGroup
              id="settingsMessageCategories"
              v-model="form.settings.messageCategories"
              :description="$t('MESSAGE_CATEGORY_DESCRIPTION')"
              :placeholder="$t('ADD_CATEGORY_PLACEHOLDER')"
              type="select-array"
              :unique-only="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="['company_owner', 'admin'].some(r => $auth.roles.includes(r))" class="row push">
      <div class="col-lg-4">
        <p class="text-muted"></p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <div class="accordin">
          <div id="settings-accordion_h1" class="card p-3 bg-body" role="tab">
            <a class="font-w600 collapsed card-header" data-toggle="collapse" href="#accordion-document-categories"
              >{{ $tc('DOCUMENT_CATEGORY', 2) }} <span class="float-right"> <i class="fas"></i> </span
            ></a>
          </div>
          <div id="accordion-document-categories" class="collapse" role="tabpanel">
            <FormGroup
              id="settingsDocumentCategories"
              v-model="form.settings.documentCategories"
              :description="$t('DOCUMENT_CATEGORY_DESCRIPTION')"
              :placeholder="$t('ADD_CATEGORY_PLACEHOLDER')"
              type="select-array"
              :unique-only="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import FormGroup from '../FormGroup';
import Spinner from '../Spinner';

const defaultForm = {
  name: '',
  settings: {
    invoiceCategories: [],
    messageCategories: [],
    calendarPeriod: 'standard',
    hideCostData: false
  }
};

export default {
  name: 'CompanyForm',
  components: {
    FormGroup,
    Spinner
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    error: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      loadingAction: 'company/loadingAction',
      errorAction: 'company/errorAction',
      logoUpload: 'company/logoUpload',
      formErrors: 'company/formErrors'
    }),
    yesNoOptions() {
      return [
        { label: this.$t('YES'), value: true },
        { label: this.$t('NO'), value: false }
      ];
    },
    calendarPeriodOptions() {
      return [
        { label: this.$t('STANDARD'), value: 'standard' },
        { label: this.$t('UK_FINANCIAL_YEAR'), value: '13weekperiod' },
        { label: this.$t('RAIL_CALENDAR'), value: 'rail' }
      ];
    }
  },
  data() {
    return {
      form: defaultForm,
      file: {},
      originalValue: {},
      companyId: ''
    };
  },
  watch: {
    value(val) {
      if (val._id !== this.form._id) {
        this.originalValue = { ...val };

        this.form = {
          name: val.name || defaultForm.name,
          settings: val.settings || defaultForm.settings
        };
      }
    },
    form(form) {
      if (this.form._id === form._id) {
        this.$emit('input', this.form);
      }
    }
  },
  async mounted() {
    this.form = {
      name: this.value.name || defaultForm.name,
      settings: this.value.settings || defaultForm.settings
    };

    this.originalValue = {
      ...this.value
    };
  },
  methods: {
    ...mapActions({
      uploadLogo: 'company/uploadLogo'
    }),
    onFileSelect(e) {
      const { files } = e.target;

      const file = files[0];

      const data = new FormData();

      data.append('logo', file, file.name);

      this.file = data;
    },
    async onSubmitFile() {
      this.error = false;
      const uploadedLogo = await this.uploadLogo({ data: this.file });
      if (uploadedLogo && uploadedLogo.s3Key) {
        this.form.logoS3Key = uploadedLogo.s3Key;
      }
    }
  }
};
</script>
<style scoped>
.card-header i.fas:before {
  content: '\f107'; /* angle-down */
}

.card-header.collapsed i.fas:before {
  content: '\f105'; /* angle-up */
}
</style>
